import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect } from 'react';

import { InputDialog } from 'wix-ui-tpa/InputDialog';
import { Spinner } from '../Spinner';

import { st, classes } from './ProductFacetsDialog.st.css';

export interface ProductFacetsDialogProps {
  isLoading: boolean;
  onClose(): void;
  onReset(): void;
}

export const ProductFacetsDialog: React.FC<ProductFacetsDialogProps> = ({
  isLoading,
  onClose,
  onReset,
  children,
}) => {
  const { t } = useTranslation();

  // This is horrible workaround to block scroll under dialog.
  // Read more info in css file above facetsModalOpen class definition
  useEffect(() => {
    const scrollCorrection = window.scrollY;
    document.body.classList.add(classes.facetsModalOpen);

    return () => {
      document.body.classList.remove(classes.facetsModalOpen);

      // Adding and removing a class resets resets scroll position
      // This set scroll position to previous value when modal is closed
      window.scrollBy(0, scrollCorrection);
    };
  }, []);

  return (
    <div className={st(classes.root, { loading: isLoading })}>
      <InputDialog
        data-hook="facets-dialog"
        className={classes.productFacetsDialog}
        title={t('searchResults.facets.mobile.title')}
        isOpen={true}
        onClose={onClose}
        primaryButton={{
          text: t('searchResults.facets.mobile.okButton.label'),
          onClick: onClose,
        }}
        secondaryButton={{
          text: t('searchResults.facets.mobile.resetButton.label'),
          onClick: onReset,
        }}
        fullscreen
      >
        <div className={classes.facetsContainer}>{children}</div>
        {isLoading && <Spinner dataHook="facets-spinner" />}
      </InputDialog>
    </div>
  );
};
